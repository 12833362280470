import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

import Slider from "react-animated-slider"
import "react-animated-slider/build/horizontal.css"

export default function HeaderSlider({ artistName, slides, sliderOptions }) {
  return (
    <SliderWrapper>
      <Slider
        autoplay={sliderOptions.autoplay}
        previousButton={sliderOptions.previousButton}
        nextButton={sliderOptions.nextButton}
        touchDisabled={sliderOptions.touchDisabled}
        duration={sliderOptions.duration}
      >
        {slides.map((slide, index) => (
          <div key={index}>
            <SliderImg
              fluid={slide.node.childImageSharp.fluid}
              alt={`Portrait of ${artistName}`}
            />
          </div>
        ))}
      </Slider>
    </SliderWrapper>
  )
}

const SliderImg = styled(Img)`
  max-height: calc(100vh - 3em);
  min-height: calc(100vh - 3em);
  @media (min-width: 768px) {
    max-height: calc(100vh - 4em);
    min-height: calc(100vh - 4em);
  }
`
const SliderWrapper = styled.div`
  & .slider {
    max-height: calc(100vh - 3em);
    min-height: calc(100vh - 3em);
    @media (min-width: 768px) {
      max-height: calc(100vh - 4em);
      min-height: calc(100vh - 4em);
    }
  }
`
